'use strict';

(function () {
  'use strict';

  ScrollReveal().reveal('.quote-holder', {
    distance: '100px',
    origin: 'bottom',
    duration: 800,
    easing: 'ease-in-out',
  });
  ScrollReveal().reveal(
    '.section-about, .section-members, .section-poster, .video-holder, .facilities-holder',
    {
      distance: '200px',
      delay: 200,
      origin: 'bottom',
      duration: 1000,
      easing: 'ease-in-out',
    }
  );
  $('.kv-slider').slick({
    dots: true,
    autoplay: true,
    arrows: false,
    infinite: true,
    autoplaySpeed: 9000,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          variableWidth: true,
          centerMode: true,
        },
      },
    ],
  });

  var posterSlider = $('#posterSlider');

  posterSlider.slick({
    centerMode: true,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    variableWidth: true,
    slidesToShow: 6,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
  });
  $('.slick-btn--custom').on('click', function (e) {
    e.preventDefault();
    var slick = $(this).data('id');
    var id = $(this).data('slide');
    console.log(slick, id);
    $('#' + id).slick(slick);
  });
  $('#facilitiesSlider').slick({
    variableWidth: true,
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          variableWidth: false,
          centerPadding: '40px',
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  $('.video-holder').slick({
    centerPadding: '40px',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,

    centerMode: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 768,
        settings: 'unslick',
      },
    ],
  });
  window.dispatchEvent(new Event('resize'));
})();
